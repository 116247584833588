import api from '@/services/api'

export default {
  sendNotification(data) {
    return api.post('/users/notification', data);
  },

  getNotifications() {
    return api.get('/notifications');
  },

  removeNotification(notificationId) {
    return api.delete('/notifications/' + notificationId);
  },
}