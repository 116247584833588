<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import UserService from '@/services/notification';
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br'

export default {
  page: {
    title: "Notificações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, DatePicker },
  data() {
    return {
      title: "Notificações",
      notification: {
        title: '',
        message: '',
        intelligentDelivery: true,
        user: 'all',
        destination: 'all',
        destinationParams: '30',
        schedule: false,
        scheduleTime: '08:00',
        scheduleDate: new Date(),
      }
    };
  },
  methods: {
    sendNotification() {
      if (!this.notification.title || !this.notification.message) {
        return this.$toast.warning('Preencha todos os campos');
      }

      if (this.notification.schedule && (!this.notification.scheduleDate || !this.notification.scheduleTime)) {
        return this.$toast.warning('Preencha a data e horário do agendamento da notificação');
      }
      
      let loader = this.$loading.show();

      UserService.sendNotification(this.notification).then(() => {
        loader.hide();

        if (this.notification.schedule) {
          this.$toast.open('Mensagem agendada com sucesso');
          this.$router.push('/schedule-notifications');
        } else {
          this.$toast.open('Mensagem enviada com sucesso');
        }

        this.notification = {
          title: '',
          message: '',
          intelligentDelivery: true,
          user: 'all',
          destination: 'all',
          destinationParams: '30',
          schedule: false,
          scheduleTime: '08:00',
          scheduleDate: new Date(),
        }
      }).catch((err) => {

        this.$toast.error(err);
        loader.hide();
      });
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <!-- <h4 class="card-title">Notificação Push</h4>
        <p class="card-title-desc">A mensagem será enviada para todos os dispositivos com o aplicativo instalado</p> -->
        <form>
          <div class="row">
            <div class="col-sm-6">
              <b-alert show variant="warning">Importante: A mensagem será encaminhada para TODOS os celulares com o aplicativo instalado</b-alert>
              <div class="form-group">
                <label for="title">Título da Mensagem</label>
                <input id="title" name="title" type="text" class="form-control" v-model="notification.title"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="title">Mensagem</label>
                <textarea id="message" class="form-control" rows="6" v-model="notification.message" placeholder="Informe aqui o conteúdo da mensagem"></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label>Enviar para</label>
                <select class="form-control" v-model="notification.destination">
                  <option value="all">TODOS USUÁRIOS</option>
                  <option value="noSales">Clientes que não compra a mais de</option>
                  <option value="noAccess">Clientes que não acessam a mais de</option>
                </select> 
              </div>
            </div>

            <div class="col-sm-2" v-if="notification.destination !== 'all'">
              <div class="form-group">
                <label for="destinationParams">Dias</label>
                <input id="destinationParams" name="destinationParams" type="tel" class="form-control" v-model="notification.destinationParams"/>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="custom-control custom-checkbox mb-3">
                  <input type="checkbox" class="custom-control-input" id="intelligentDelivery" v-model="notification.intelligentDelivery" />
                  <label class="custom-control-label" for="intelligentDelivery">Utilizar entrega inteligente</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="custom-control custom-checkbox mb-3">
                  <input type="checkbox" class="custom-control-input" id="schedule" v-model="notification.schedule" />
                  <label class="custom-control-label" for="schedule">Agendar envio</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="notification.schedule">
            <div class="col-3">
              <div class="form-group">
                <label for="scheduleDate">Data</label>
                <date-picker
                  v-model="notification.scheduleDate"
                  format="DD/MM/YYYY"
                  :first-day-of-week="1"
                ></date-picker>
              </div>
            </div>

            <div class="col-3">
              <div class="form-group">
                <label for="scheduleTime">Horário</label>
                <date-picker
                  v-model="notification.scheduleTime"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  :minute-step="10"
                ></date-picker>
              </div>
            </div>
          </div>

          <div class="row mt-2 align-items-center">
            <div class="col-sm-6">
              <div class="text-sm-right">
                <!-- <b-spinner class="m-2" variant="primary" role="status" v-if="loading"></b-spinner> -->
                <button type="button" class="btn btn-primary btn-rounded mb-2" @click="sendNotification">
                  <i class="mdi mdi-check mr-1"></i> {{ notification.schedule ? 'Agendar Mensagem' : 'Enviar Mensagem' }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
